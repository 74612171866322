<template>
  <v-container
    class="signup-form tw-bg-white tw-rounded-t-3xl tw-pt-10 tw-px-10"
  >
    <global-alert v-if="globalAlert.show" />
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-form @submit.prevent="patchUser">
        <div v-if="firstStep">
          <v-col>
            <h2 class="tw-text-lg tw-font-semibold tw-text-charcoal">
              Create Account
            </h2>
            <p class="tw-mb-0 tw-text-light-grey tw-text-base tw-leading-22">
              Step 1 of 2
            </p>
          </v-col>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="password"
              rules="required|password"
            >
              <j-text-field
                color="secondary"
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :hide-details="errors.length === 0"
                :error-messages="errors"
                placeholder="Set password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :prepend-inner-icon="'mdi-lock'"
                @toggle="showPassword = !showPassword"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="confirmation"
              rules="required|confirmed:password"
            >
              <j-text-field
                color="secondary"
                v-model="confirmation"
                :type="showPasswordConfirmation ? 'text' : 'password'"
                :hide-details="errors.length === 0"
                :error-messages="errors"
                placeholder="Confirm password"
                :append-icon="
                  showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'
                "
                :prepend-inner-icon="'mdi-lock'"
                @toggle="showPasswordConfirmation = !showPasswordConfirmation"
              />
            </validation-provider>
          </v-col>
        </div>
        <div v-if="secondStep" class="tw-mb-16">
          <v-col>
            <h2 class="tw-text-lg tw-font-semibold tw-text-charcoal">
              Create Profile
            </h2>
            <p class="tw-mb-0 tw-text-light-grey tw-text-base tw-leading-22">
              Step 2 of 2
            </p>
          </v-col>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="First name"
              rules="required"
            >
              <j-text-field
                label="Name*"
                color="secondary"
                v-model="firstName"
                type="text"
                :hide-details="errors.length === 0"
                :error-messages="errors"
                placeholder="First name"
                customClasses="tw-text-left"
                :readonly="lockedName"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="Last name"
              rules="required"
            >
              <j-text-field
                color="secondary"
                v-model="lastName"
                type="text"
                :hide-details="errors.length === 0"
                :error-messages="errors"
                placeholder="Last name"
                customClasses="tw-text-left"
                :readonly="lockedName"
              />
            </validation-provider>
          </v-col>
          <v-col>
            <j-tel-input
              label="Phone"
              :inputOptions="{ placeholder: 'Enter phone number' }"
              @blur="blurNumber($event)"
              v-model="phone"
            ></j-tel-input>
            <span
              class="tw-text-xxs tw-text-ninthgrey tw--mt-1 tw-text-left tw-ml-5 tw-block"
              v-if="validPhone"
              >Number must be verified to receive SMS notifications.</span
            >
          </v-col>
          <v-col cols="12" v-if="showVerificationCodeField">
            <span
              class="tw-block tw-text-dark-green tw-text-left tw-font-semibold tw--mt-3"
              >Enter Verification Code</span
            >
            <span
              class="tw-block tw-text-left tw-text-light-grey tw-text-xs tw-mb-2"
              >Enter code sent to {{ phone }}.
              <a
                href="#"
                @click.prevent="verifyNumber('resend')"
                class="tw-text-charcoal tw-font-bold tw-text-xs"
                >Resend.</a
              ></span
            >
            <validation-provider
              v-slot="{ errors }"
              name="Verification Code"
              rules="required"
            >
              <!-- <j-text-field
                color="secondary"
                v-model="code"
                type="text"
                :hide-details="errors.length === 0"
                :error-messages="errors"
                customClasses="tw-text-left"
              /> -->
              <v-otp-input
                color="secondary"
                length="5"
                v-model="code"
                :error-messages="errors"
                :hide-details="errors.length === 0"
              ></v-otp-input>
            </validation-provider>
            <p
              class="tw-text-left tw-text-xs tw-leading-5 tw-text-red tw-w-90 tw-mx-auto"
              v-if="codeVerificationError"
            >
              We were unable to verify the code. Tap resend to try again and
              ensure your phone number is correct.
            </p>
            <v-btn
              @click="verifyCode()"
              x-large
              rounded
              block
              depressed
              class="j-btn tw-bg-chartreuse tw-tracking-normal tw-mt-4"
              :disabled="!code || code.length < 5 || invalid"
              >Verify Number</v-btn
            >
            <a
              href="#"
              class="tw-text-dark-green tw-border-b tw-border-solid tw-border-dark-green tw-font-bold tw-inline-block tw-mt-4"
              @click.prevent="patchUser"
              v-if="firstName && lastName"
            >
              I'll verify later
            </a>
          </v-col>
        </div>
        <div v-if="thirdStep" class="tw-mb-2">
          <v-col>
            <h2 class="tw-text-lg tw-font-semibold tw-text-charcoal">
              Set Notifications
            </h2>
            <p class="tw-mb-0 tw-text-light-grey tw-text-base tw-leading-22">
              Step 3 of 3
            </p>
          </v-col>
          <v-col class="tw-text-left">
            <p class="tw-mb-0 tw-font-semibold tw-text-dark-green">
              Stay updated on your travel plans
            </p>
            <p class="tw-text-sm tw-text-light-grey">
              You'll receive notifications on:
            </p>
            <ul class="tw-pl-0 tw-pt-4 tw-pb-8">
              <li
                class="tw-flex tw-flex-row tw-items-center tw-justify-start tw-mb-2.5"
              >
                <img
                  src="@/assets/svgs/checkmark-serif.svg"
                  alt="Checkmark"
                  class="tw-mr-2 tw-inline-block"
                />
                <span>RSVPs & Who's Going</span>
              </li>
              <li
                class="tw-flex tw-flex-row tw-items-center tw-justify-start tw-mb-2.5"
              >
                <img
                  src="@/assets/svgs/checkmark-serif.svg"
                  alt="Checkmark"
                  class="tw-mr-2 tw-inline-block"
                /><span>Finalized trip details</span>
              </li>
              <li
                class="tw-flex tw-flex-row tw-items-center tw-justify-start tw-mb-2.5"
              >
                <img
                  src="@/assets/svgs/checkmark-serif.svg"
                  alt="Checkmark"
                  class="tw-mr-2 tw-inline-block"
                /><span>Crew Posts & Reactions</span>
              </li>
              <li
                class="tw-flex tw-flex-row tw-items-center tw-justify-start tw-mb-2.5"
              >
                <img
                  src="@/assets/svgs/checkmark-serif.svg"
                  alt="Checkmark"
                  class="tw-mr-2 tw-inline-block"
                /><span>Trip Survey & Results</span>
              </li>
              <!-- <li
                class="tw-flex tw-flex-row tw-items-center tw-justify-start tw-mb-2.5"
              >
                <img
                  src="@/assets/svgs/checkmark-serif.svg"
                  alt="Checkmark"
                  class="tw-mr-2 tw-inline-block"
                /><span>Reminders & To Do’s</span>
              </li> -->
            </ul>
            <div class="tw-py-10">
              <p class="tw-font-semibold tw-text-dark-green">
                How would you like to be notified?
              </p>
              <div
                class="tw-mb-6 tw-flex tw-flex-row tw-justify-between tw-items-center"
              >
                <span
                  class="tw-flex tw-flex-row tw-items-center tw-justify-start"
                >
                  <img
                    src="@/assets/svgs/profile/sms-notifications.svg"
                    alt="SMS Notifications"
                    class="tw-mr-3 tw-mt-1"
                  />
                  <p class="tw-text-black tw-mt-1 tw-mb-0">
                    SMS Notifications<br /><span
                      class="tw-absolute tw-font-extrabold tw-text-xxxxs tw-text-j-pink"
                      >COMING SOON!</span
                    >
                  </p>
                </span>
                <v-switch
                  color="secondary"
                  hide-details
                  v-model="receiveSMS"
                  inset
                  class="tw-mt-0"
                />
              </div>
              <div
                class="tw-mb-6 tw-flex tw-flex-row tw-justify-between tw-items-center"
              >
                <span
                  class="tw-flex tw-flex-row tw-items-center tw-justify-start"
                >
                  <img
                    src="@/assets/svgs/profile/email-notifications.svg"
                    alt="Email Notifications"
                    class="tw-mr-3 tw-mt-1"
                  />
                  <p class="tw-text-black tw-mt-1 tw-mb-0">
                    Email Notifications
                  </p>
                </span>
                <v-switch
                  color="secondary"
                  hide-details
                  v-model="receiveEmails"
                  inset
                  class="tw-mt-0"
                />
              </div>
            </div>
            <p class="tw--mt-10 tw-text-sm tw-text-light-grey">
              NOTE: It’s important notifications are enabled to stay updated on
              trip details. These are NOT promotional emails. We’ve included a
              separate opt-in for that below.
            </p>
          </v-col>
          <v-col cols="12" class="tw-text-left">
            <div class="tw-pt-5">
              <p class="tw-mb-0 tw-font-semibold tw-text-dark-green">
                Notify me with feature updates & offers.
              </p>
              <p class="tw-text-sm tw-text-light-grey">
                Stay updated on new features from Let’s Jetty and receive
                exclusive offers for your next trip.
              </p>
            </div>
            <div
              class="tw-mb-6 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b tw-border-solid tw-border-light-grey tw-pb-10"
            >
              <span
                class="tw-flex tw-flex-row tw-items-center tw-justify-start"
              >
                <img
                  src="@/assets/svgs/profile/travel-deals-notifications.svg"
                  alt="Email me updates & offers"
                  class="tw-mr-3 tw-mt-1"
                />
                <p class="tw-text-black tw-mt-1 tw-mb-0">
                  Email me updates & offers
                </p>
              </span>
              <v-switch
                color="secondary"
                hide-details
                v-model="receiveMarketingEmails"
                inset
                class="tw-mt-0"
              />
            </div>
            <div
              class="tw-text-11 tw-p-4 tw-text-j-pink tw-text-center tw-border tw-border-solid tw-border-j-pink tw-rounded-md tw-mt-8"
            >
              You can change these settings in your profile at any time.
            </div>
          </v-col>
        </div>
        <v-col class="tw-mb-5">
          <v-btn
            v-if="
              firstStep ||
              (secondStep &&
                !(
                  firstName &&
                  lastName &&
                  validPhone &&
                  phone &&
                  phone.length !== 0
                ))
            "
            @click="goToNextStep"
            x-large
            rounded
            block
            depressed
            class="j-btn tw-bg-chartreuse tw-tracking-normal"
            :disabled="invalid"
            >{{ secondStep ? "Done" : "Next Step" }}</v-btn
          >
          <v-btn
            v-show="thirdStep"
            :loading="isSignupPending"
            :disabled="invalid || isSignupPending"
            x-large
            rounded
            block
            depressed
            type="submit"
            class="j-btn tw-bg-chartreuse tw-tracking-normal"
            >Continue</v-btn
          >
        </v-col>
        <div v-if="firstStep">
          <v-col class="mb-4">
            <PasswordDisclaimer />
          </v-col>
          <v-col
            class="disclaimer tw-text-light-grey tw-text-xxs tw-pb-2 tw-leading-5"
          >
            <p class="tw-mb-0">By signing up, you agree to our:</p>
            <div class="d-flex flex-row justify-center align-center">
              <router-link
                to="/privacy-policy"
                class="router-link tw-underline tw-text-light-grey"
                >PRIVACY POLICY</router-link
              >
              <span class="tw-mx-1 tw-my-0">&#9679;</span>
              <router-link
                to="/terms-and-conditions"
                class="router-link tw-underline tw-text-light-grey"
                >TERMS AND CONDITIONS</router-link
              >
            </div>
          </v-col>
        </div>
      </v-form>
    </validation-observer>
    <v-dialog
      v-model="showModal"
      max-width="400"
      content-class="tw-rounded-3xl"
    >
      <div class="tw-text-left tw-py-10 tw-px-5 tw-bg-white">
        <div class="tw-max-h-96 tw-overflow-y-scroll tw-max-w-260 tw-mx-auto">
          <span class="tw-text-34 tw-text-red tw-font-bold">‼️</span>
          <p class="tw-text-left tw-font-semibold tw-text-22">
            Your email notifications are turned off
          </p>
          <p class="tw-text-left tw-text-light-grey tw-text-sm">
            You won’t get updated on important details related to trips
            including dates, destination, accommodations, who’s going, etc.
          </p>
          <p class="tw-text-left tw-text-light-grey tw-text-sm">
            Please note, SMS are not currently available, but will be coming
            soon.
          </p>
          <p class="tw-text-left tw-font-semibold">
            Are you sure you want to proceed?
          </p>
        </div>
        <v-btn
          :loading="isSignupPending"
          :disabled="isSignupPending"
          x-large
          rounded
          block
          depressed
          type="button"
          class="j-btn tw-bg-chartreuse tw-tracking-normal"
          @click="patchUser()"
          >Yes, I don't want trip notifications</v-btn
        >
        <button
          class="btn-tertiary tw-block tw-mx-auto tw-mt-4"
          @click="showModal = !showModal"
        >
          Go Back
        </button>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import { DateTime } from "luxon";
import PasswordDisclaimer from "@/components/shared/PasswordDisclaimer.vue";
import { PASSWORD } from "@/enums/validation-regex.js";
import GlobalAlert from "@/components/alerts/GlobalAlert";

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export default {
  name: "SignupForm",
  components: {
    PasswordDisclaimer,
    GlobalAlert
  },
  data() {
    return {
      showModal: false,
      firstStep: true,
      secondStep: false,
      thirdStep: false,
      email: null,
      phone: null,
      password: null,
      passwordRegex: PASSWORD.pattern,
      confirmation: null,
      firstName: null,
      lastName: null,
      code: null,
      receiveSMS: true,
      receiveEmails: true,
      receiveMarketingEmails: false,
      showPassword: false,
      showPasswordConfirmation: false,
      validPhone: false,
      fullNumber: null,
      codeVerificationError: false,
      showVerificationCodeField: false,
      codeVerificationID: null,
      user: null,
      lockedName: false,
      timeout: null
    };
  },
  computed: {
    globalAlert() {
      return this.$store.state.meta.globalAlert;
    },
    isSignupPending() {
      return this.$store.state.users.isCreatePending;
    }
  },
  watch: {
    phone: function (val) {
      if (!val || !this.validPhone) this.showVerificationCodeField = false;
    },
    firstName: debounce(function (val) {
      if (val && this.lastName && this.validPhone) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => this.createUser("verification"), 1000);
      } else {
        this.showVerificationCodeField = false;
      }
    }, 300),
    lastName: debounce(function (val) {
      if (val && this.firstName && this.validPhone) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => this.createUser("verification"), 1000);
      } else {
        this.showVerificationCodeField = false;
      }
    }, 300)
  },
  methods: {
    blurNumber(e) {
      this.validPhone = e.valid;
      if (this.validPhone && e.number) {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: false
        });
        this.fullNumber = e.number;
        //check if first name and last name exist and create the user
        if (this.firstName && this.lastName)
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
        this.timeout = setTimeout(() => this.createUser("verification"), 1000);
      } else {
        this.fullNumber = null;
        //hide verification field if the number is invalid
        this.showVerificationCodeField = false;
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: true
        });
      }
    },
    verifyCode() {
      this.$store
        .dispatch("consume-phone-verification/create", {
          id: this.codeVerificationID,
          code: this.code
        })
        .then(() => {
          this.showVerificationCodeField = false;
          this.patchUser();
        })
        .catch((err) => {
          this.codeVerificationError = true;
          console.error(err);
        });
    },
    async verifyNumber(type = null) {
      const verifyNumberRes = await this.$store.dispatch(
        "create-phone-verification/create",
        {}
      );
      this.codeVerificationID = verifyNumberRes.id;

      if (type) {
        this.$store.dispatch("meta/showGlobalAlert", {
          type: "success",
          text: "Code was re-sent.",
          timeout: 2000
        });
      }
    },
    async createUser(caller = null) {
      if (!this.firstName || !this.lastName) return;
      await this.$store
        .dispatch("users/create", {
          email: this.email,
          password: this.password,
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.fullNumber
        })
        .then((res) => {
          this.lockedName = true;
          let signupTripId = null;
          if (localStorage.getItem("invitationPending")) {
            signupTripId = JSON.parse(
              localStorage.getItem("invitationPending")
            ).tripId;
          }
          window.dataLayer.push({
            event: "sign_up",
            uuid: res.uuid,
            sign_up_trip_id: signupTripId,
            timestamp: DateTime.now().toISO()
          });
          this.user = res;
          this.$store
            .dispatch("auth/authenticate", {
              email: this.user.email,
              password: this.password,
              strategy: "local"
            })
            .then(() => {
              if (caller === "verification") {
                this.verifyNumber();
                this.showVerificationCodeField = true;
              } else {
                this.patchUser();
              }
            });
        })
        .catch((error) => {
          if (
            error.errors &&
            error.errors[0].validatorKey &&
            error.errors[0].validatorKey === "not_unique"
          ) {
            if (caller === "verification") {
              this.verifyNumber();
              this.showVerificationCodeField = true;
            } else {
              this.patchUser();
            }
          }
          console.error(error);
        });
    },
    async patchUser() {
      await this.$store
        .dispatch("users/patch", [
          this.$store.state.auth.user.id,
          {
            receiveSMS: this.receiveSMS,
            receiveEmails: this.receiveEmails,
            receiveMarketingEmails: this.receiveMarketingEmails
          }
        ])
        .then(() => {
          if (this.$route.query.ref) {
            this.$router.push({
              path: this.$route.query.ref
            });
          } else {
            this.$router.push({
              name: "TripsRoute"
            });
          }
        });
    },
    goToNextStep() {
      if (this.firstStep) {
        this.firstStep = false;
        this.secondStep = true;
        this.$emit("second-step");
      } else if (this.secondStep) {
        this.createUser();
      }
    },
    sendSignupEvent() {
      if (document.cookie.indexOf("signup_event_triggered") !== -1)
        return false;
      return true;
    },
    getSublabel() {
      return `Enter code sent to ${this.phone}`;
    },
    setCookie(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    // createAccount() {
    //   if (!this.receiveEmails) {
    //     this.showModal = true;
    //   } else {
    //     this.patchUser();
    //   }
    // },
  },
  beforeMount() {
    this.email = this.$route.query.email;
  },
  mounted() {
    let landedFromInvitationPage = false;
    if (localStorage.getItem("invitationPending"))
      landedFromInvitationPage = true;
    if (this.sendSignupEvent()) {
      //send dataLayer signup event and set the cookie
      this.setCookie("signup_event_triggered", "true", 365);
      window.dataLayer.push({
        event: "signup_trigger",
        landed_from_invitation_page: landedFromInvitationPage
      });
    }
  }
};
</script>

<style lang="scss">
.signup-form
  .v-form
  .v-text-field--outlined
  > .v-input__control
  > .v-input__slot {
  background-color: #f7f6f5;
}

.signup-form .v-form .v-text-field--outlined fieldset {
  border-color: #f7f6f5;
}

.j-input-switch {
  @apply tw-mt-0 tw-pt-0;

  .v-input__control .v-input__slot label {
    @apply tw-text-black tw-font-normal;
  }
}

.global-alert {
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
