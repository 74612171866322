<template>
  <div class="signup tw-bg-chartreuse tw-max-w-full tw-max-h-full">
    <v-container class="tw-p-0">
      <Header
        v-if="!defaultHeaderDisabled && headerVisible"
        :page-title="pageTitle"
        :bg-color="bgColor"
        :font-color="fontColor"
        :tagline="tagline"
        :icon-one="iconOne"
        :icon-two="iconTwo"
      ></Header>
      <v-container class="tw-py-16" v-else>
        <v-row>
          <v-col>
            <v-img
              :width="46"
              src="@/assets/lets-jetty.svg"
              class="tw-block tw-mx-auto tw-mb-5"
            ></v-img>
            <h1
              class="tw-text-dark-green tw-leading-none tw-text-64 tw-font-bebas-neue"
            >
              LET'S JETTY
            </h1>
          </v-col>
        </v-row>
      </v-container>
      <signup-form @second-step="toggleHeader" />
    </v-container>
  </div>
</template>

<script>
import SignupForm from "@/components/auth/SignupForm";
import jettyLogo from "@/assets/lets-jetty.svg";
import Header from "@/components/Header";

export default {
  components: {
    SignupForm,
    Header,
  },
  computed: {
    defaultHeaderDisabled() {
      return this.$store.state.meta.header.defaultHeaderDisabled;
    },
    pageTitle() {
      return this.$store.state.meta.header.pageTitle;
    },
    bgColor() {
      return this.$store.state.meta.header.bgColor;
    },
    fontColor() {
      return this.$store.state.meta.header.fontColor;
    },
    tagline() {
      return this.$store.state.meta.header.tagline;
    },
    iconOne() {
      return this.$store.state.meta.header.iconOne;
    },
    iconTwo() {
      return this.$store.state.meta.header.iconTwo;
    },
    mainBgColor() {
      return this.$store.state.meta.style.mainBgColor;
    },
    globalAlert() {
      return this.$store.state.meta.globalAlert;
    },
  },
  data() {
    return {
      headerVisible: null,
    };
  },
  methods: {
    toggleHeader() {
      this.headerVisible = true;
      this.$store.commit("meta/setHeader", {
        defaultHeaderDisabled: false,
        pageTitle: "CREATE ACCOUNT",
        bgColor: "#E6FFA1",
        fontColor: "#203848",
        tagline: null,
        iconOne: "mdi-bag-suitcase",
        iconTwo: jettyLogo,
      });
    },
  },
};
</script>
